@import 'variables';
@import 'breakpoints';

@mixin paint-button($background, $text) {
  background: $background;
  color: $text;

  &:hover {
    background: darken($background, 5%);
  }

  &.inverted:not([disabled]) {
    background-color: $text;
    border: 2px solid $background;
    color: $background;

    &:hover {
      background-color: darken($text, 5%);
    }

    &.icon {
      color: $background;

      i {
        &:before {
          color: $background;
        }
      }
    }

    &[class*="icon-"] {
      &:before {
        color: $background;
      }
    }
  }

  &.icon {
    color: $text;

    i {
      &:before {
        color: $text;
      }
    }
  }

  &[class*="icon-"] {
    &:before {
      color: $text;
    }
  }
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  align-items: center;
  border-radius: 6px;
  display: inline-block;
  font-weight: 600;
  font-size: $fs-small;
  height: 40px;
  justify-content: center;
  line-height: 18px;
  min-width: 84px;
  padding: 0 22px;
  position: relative;
  text-align: center;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &.basic {
    @include paint-button($color-white, $color-primary);
    border: 1px solid $color-border-gray;
  }

  &.primary {
    @include paint-button($color-primary, $color-white);
  }

  &.secondary {
    @include paint-button($color-secondary, $color-white);
  }

  &.danger {
    @include paint-button($color-error, $color-white);
  }

  &.gradient {
    background: $color-gradient-blue;
    color: $color-white;
    border: 2px solid $color-white;

    &:hover {
      background: $color-gradient-blue-dark;
    }

    &[class*="icon-"] {
      &:before {
        color: $color-white;
      }
    }
  }

  &.link {
    @include paint-button(transparent, $color-primary);
    background: none;
    height: auto;
    min-width: auto;
    padding: 0;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
      border: none;
    }

    &[disabled] {
      background: transparent;
    }
  }

  &[class*=" icon-"], &[class^="icon-"], &[class*=" fa-"], &[class^="fa-"] {
    // if button has inner content
    &:not(:empty):not(.mat-mdc-button-base) {
      padding-left: 35px;

      &:before {
        font-size: 16px;
        position: absolute;
        left: 8px;
        // font-size: 20px;
        // margin-right: 10px;
      }
    }

    // if button has no inner content
    &:empty {
      align-items: center;
      background-color: white;
      border-radius: 5px;
      cursor: pointer;
      //display: flex;
      font-size: 20px;
      padding: 0;
      height: 30px;
      min-width: 30px;
      justify-content: center;
      width: 30px;

      @include device-small {
        font-size: 24px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }

      &:hover {
        background-color: darken($color-white, 5%);
      }

      &:before {
        font-size: $fs-default;
      }
    }

    &.disabled, &[disabled] {
      background: transparent !important;
      &:before {
        color: $color-text !important;
      }
      &:hover {
        background: transparent !important;
      }
    }
  }

  &.block {
    width: 100%;
  }

  &[disabled], .disabled {
    @include paint-button($color-border, $color-text);
    background: $color-border !important;
    cursor: default;

    &:hover {
      background-color: $color-border;
    }

    label {
      cursor: default;
    }
  }

  &.compact {
    min-width: auto;
    padding: 0 10px;
  }

  &.high {
    height: 50px;
  }

  &.header-button {
    align-items: center !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    min-width: auto !important;
    height: 40px !important;
    padding: 0 !important;
    width: 40px !important;

    &:before {
      color: white !important;
      font-size: 24px !important;
      line-height: 24px !important;
      margin-right: 0 !important;
      position: static !important;
    }

    &:hover {
      background-color: $color-primary-hover !important;
    }
  }

  &.badge-button {
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 5px !important;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    min-width: auto;

    &:before {
      font-size: 12px !important;
      position: static !important;
      margin-right: 5px !important;
    }

    &.mat-mdc-menu-trigger {
      display: flex;
      align-items: center;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-width: 5px;
        border-style: solid;
        border-color: transparent;
        margin-left: 5px;
        margin-top: 5px;
      }
    }

    &.primary {
      color: $color-primary;
      border-color: $color-primary;
      background-color: rgba($color-primary, .1);

      &:before {
        color: $color-primary;
      }

      &.mat-mdc-menu-trigger {
        &:after {
          border-top-color: $color-primary;
        }
      }
    }

    &.warning {
      color: $color-warning;
      border-color: $color-warning;
      background-color: rgba($color-warning, .1);

      &:before {
        color: $color-warning;
      }

      &.mat-mdc-menu-trigger {
        &:after {
          border-top-color: $color-warning;
        }
      }
    }

    &.default {
      color: $color-text;
      border-color: $color-text;
      background-color: rgba($color-text, .1);

      &:before {
        color: $color-text;
      }

      &.mat-mdc-menu-trigger {
        &:after {
          border-top-color: $color-text;
        }
      }
    }
  }

  &+.btn {
    margin-left: 10px;
  }

  label {
    cursor: pointer;
  }
}
