@import 'styles/variables';
@import 'styles/breakpoints';

.mat-stepper-horizontal {
  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-horizontal-stepper-header-container {
    align-items: center;
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
    width: 100%;

    .mat-step-header {
      width: 100%;

      // Hide step numbers, edit, error icons
      .mat-step-icon-state-number,
      .mat-step-icon-state-edit,
      .mat-step-icon-state-error {
        display: none;
      }

      &.mat-horizontal-stepper-header {
        height: auto;
        padding: 0;
        display: contents;

        &:hover {
          background: transparent;
        }
      }

      .mat-step-label {
        font-size: $fs-big;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;

        &:not(.mat-step-label-error) {
          color: $color-text;
        }

        &.mat-step-label-selected {
          color: $color-primary;
        }
      }

      &[aria-disabled="true"] {
        pointer-events: none;
      }

      .mat-step-label:not(.mat-step-label-active) {
        pointer-events: none;

        .mat-step-text-label {
          pointer-events: none;
          user-select: none;
        }
      }
    }

    .mat-stepper-horizontal-line {
      border: none;
    }
  }

  // stepper default style
  &.default {
    .mat-horizontal-stepper-header-container {
      align-items: flex-start;

      @include device-small {
        display: flex;
        justify-content: space-between;
      }

      @include device-small {
        align-items: center;
      }
      .mat-step-header {
        @include device-small {
          &[aria-selected="false"] {
            .mat-step-label {
              display: none;
            }
          }
        }
        .mat-step-label {
          .mat-step-text-label {
            .step-label {
              color: $color-secondary;
              font-size: $fs-extra-big;
              font-weight: 600;
              margin-bottom: 5px;

              @include device-small {
                font-size: $fs-big;
              }
            }

            .step-sub-label {
              color: $color-text;
              font-size: $fs-default;
              white-space: normal;
              max-width: 90%;

              @include device-small {
                font-size: $fs-small;
                max-width: 140px;
              }
            }
          }
        }

        .mat-step-icon-state-number,
        .mat-step-icon-state-edit,
        .mat-step-icon-state-error {
          background-color: rgba($color-primary, .1);
          border: 1px solid rgba($color-secondary, .4);
          border-radius: 4px;
          color: $color-secondary;
          display: block;
          font-size: $fs-small;
          font-weight: 700;
          height: 32px;
          width: 32px;

          &.mat-step-icon-selected {
            background-color: $color-primary;
            color: $color-white;
          }
        }

        .mat-step-label {
          font-size: $fs-big;
          text-transform: none;
          font-weight: 500;
          text-align: left;
        }

        .mat-step-icon {
          @include device-small {
            margin-right: 0;
          }

          mat-icon {
            &:before {
              content: "\e90e";
              font-family: 'icomoon' !important;
            }
          }
        }
      }

      .mat-stepper-horizontal-line {
        @include device-small {
          border: 1px dashed $color-text;
          max-width: 20px;
        }
      }
    }
  }

  // centered stepper header
  &.center {
    .mat-horizontal-stepper-header-container {
      width: 50%;
      margin: 0 auto;
    }
  }

  &.no-line {
    .mat-stepper-horizontal-line {
      display: none !important;
    }
  }
}


mat-stepper.scrollable {
  height: 100%;
  display: flex;
  flex-direction: column;

  .mat-horizontal-stepper-header-container {
    justify-content: center;

    mat-step-header.mat-step-header {
      height: 40px;
    }
  }

  .mat-horizontal-content-container {
    flex-grow: 1;
    padding: 0;
    position: relative;

    .mat-horizontal-stepper-content {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .mat-horizontal-content-container {
      padding: 0 0 24px 0;
    }
  }
}
