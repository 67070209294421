@import 'styles/variables';

mat-slider.mat-mdc-slider, mat-slider.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #{$color-primary};
  --mdc-slider-focus-handle-color: #{$color-primary};
  --mdc-slider-active-track-color: #{$color-primary};
  --mdc-slider-inactive-track-color: #{$color-border-secondary};
  // fixing box-shadow in knob
  --mdc-slider-handle-elevation: none;
  width: 100%;
}

//.mdc-slider {
//  height: 38px !important;
//}
//.mdc-slider__input {
//  height: 34px !important;
//}
