@import 'styles/variables';
@import 'styles/breakpoints';
@import "styles/form-controls/checkbox";

.p-checkbox {
  .p-checkbox-box {
    border: $checkbox-unchecked-border;
    border-radius: $checkbox-border-radius;
    background: transparent;

    &:hover {
      border-color: $checkbox-outline-color;
    }

    &.p-highlight {
      border: $checkbox-checked-border;
      background: $checkbox-bg-color;

      &:hover {
        border-color: $color-primary;
      }
    }

    &.p-focus {
      box-shadow: none;
    }

    .p-checkbox-icon {
      color: $color-primary;

      path {
        stroke-width: 1;
        stroke:currentColor;
      }
    }

    &.p-checkbox-disabled {
      border: $checkbox-unchecked-border;
      background-color: $checkbox-disabled-bg-color;

      .p-checkbox-icon {
        color: $checkbox-outline-color;
      }
    }

    &.p-disabled, .p-component:disabled {
      opacity: 1;
      background-color: $checkbox-disabled-bg-color;

      &.p-highlight {
        border: $checkbox-unchecked-border;

        .p-checkbox-icon {
          color: $checkbox-outline-color;
        }
      }
    }
  }

  &[disabled] {
    color: $color-text;
  }
}
