@import 'styles/variables';
@import 'styles/helpers';
@import 'styles/breakpoints';

.mat-mdc-dialog-actions {
  --mat-dialog-actions-alignment: flex-end;
}

.cdk-global-overlay-wrapper {
  @include device-small {
    align-items: end !important;
  }
}

.cdk-overlay-pane {
  &.dialog-small,
  &.dialog-medium,
  &.dialog-large,
  &.dialog-auto-width,
  &.dialog-banner,
  &.dialog-scrollable-header,
  &.dialog-full-screen,
  &.dialog-confirm,
  &.mobile-full-screen {
    // full width dialog for small phones
    --mat-dialog-container-small-max-width: 100%;

    mat-dialog-container {
      overflow: hidden;
      border-radius: 12px;
      box-shadow: $shadow;
      height: auto;

      @include device-small {
        border-radius: 12px 12px 0 0;
      }

      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          box-shadow: none;
          overflow-x: hidden;

          & > * {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: 90vh;

            .mat-dialog-title,
            .mat-mdc-dialog-title {
              flex-grow: 0;
              padding: 20px 30px;

              &:before {
                display: none;
              }

              .dialog-caption {
                color: $color-primary;
                font-size: 20px;
                font-weight: bold;
                line-height: 24px;
              }

              .dialog-subtitle {
                color: $color-text;
                font-size: 16px;
                line-height: 20px;
                margin-top: 10px;
              }

              *[mat-dialog-close] {
                position: absolute;
                top: 15px;
                right: 15px;
              }
            }

            .mat-dialog-content,
            .mat-mdc-dialog-content {
              @extend .scroll-shadows;
              flex-grow: 1;
              max-height: 100%;
              padding: 5px 30px;
            }

            .mat-dialog-actions,
            .mat-mdc-dialog-actions {
              @extend .wave-bottom;
              flex-grow: 0;
              padding: 30px;
              //display: block;
            }

            @include device-small {
              max-height: 92vh;

              .mat-dialog-title,
              .mat-mdc-dialog-title {
                padding: 20px 15px 10px;
              }

              .mat-dialog-content,
              .mat-mdc-dialog-content {
                padding: 10px 15px;
              }

              .mat-dialog-actions,
              .mat-mdc-dialog-actions {
                padding: 10px 15px 20px;
              }
            }
          }
        }
      }
      //to add optional scroll to tab groups when they're too long
      mat-tab-group {
        height: 100%;

        .mat-mdc-tab-body-wrapper {
          height: inherit
        }

        mat-tab-body {
          height: inherit
        }
      }
    }
  }

  &.dialog-small {
    mat-dialog-container {
      width: 600px;
    }
  }

  &.dialog-confirm {
    .mdc-dialog__container {
      width: 600px;

      @include device-small {
        left: 0;
        height: auto !important;
      }
    }
  }

  &.dialog-medium {
    mat-dialog-container {
      width: 800px;
    }
  }

  &.dialog-large {
    mat-dialog-container {
      width: 1000px;
    }
  }

  &.dialog-auto-width {
    mat-dialog-container {
      width: auto;
    }
  }

  &.dialog-full-screen {
    mat-dialog-container {
      width: 100vw;
      height: 100vh;

      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          & > * {
            height: 100%;
            max-height: 100vh;

            .mat-dialog-title,
            .mat-mdc-dialog-title,
            .mat-dialog-actions,
            .mat-mdc-dialog-actions {
              flex: none;
            }

            .mat-dialog-content,
            .mat-mdc-dialog-content {
              max-height: none;
            }
          }
        }
      }
    }
  }

  &.dialog-scrollable-header {
    .mat-mdc-dialog-surface {
      .scrollable-content {
        overflow: auto;

        .mat-dialog-content {
          overflow: visible !important;
        }
      }
    }
  }

  &.dialog-banner {
    mat-dialog-container {
      width: 600px;

      .mat-mdc-dialog-surface {
        .mat-dialog-title,
        .mat-mdc-dialog-title {
          padding: 30px;
          background-color: #00B7F0 !important;
          mask-image: url('/assets/img/wave.svg');
          mask-repeat: no-repeat;
          mask-position: bottom;
          mask-position-x: 65%;
          mask-size: cover;
          color: $color-white !important;

          .dialog-caption {
            color: $color-white !important;
          }

          &:before {
            display: none;
          }

          i {
            font-size: 50px;
            margin-right: 50px;
            position: relative;
            top: 20px;
          }

          @include device-small {
            background-color: transparent;
            -webkit-mask-image: none;
          }
        }

        .mat-dialog-content,
        .mat-mdc-dialog-content {
          display: flex;
          margin-top: 15px;

          .banner-image {
            background: url('/assets/img/money.svg') no-repeat;
            height: 250px;
            width: 250px;

            @include device-small {
              height: 100px;
              width: 100px;
            }
          }

          .content-wrapper {
            .advantages-list {
              li {
                display: flex;
                margin: 10px 0;

                i {
                  margin: 5px 5px 0 0;

                  &:before {
                    color: $color-success;
                    font-size: 20px;
                  }

                }
              }
            }

            .button-area {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }

  &.mobile-full-screen {
    @include device-small {
      mat-dialog-container {
        border-radius: 0;

        .mdc-dialog__container {
          height: 100% !important;

          .mat-mdc-dialog-surface {
            & > * {
              max-height: 100vh;
            }
          }
        }
      }
    }
  }

  div.mat-mdc-select-panel {
    min-width: 200px;
  }
}
